import { Search } from '@mui/icons-material';
import { Box, CircularProgress, InputAdornment, SxProps, Theme } from '@mui/material';
import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react';
import { DefaultTextField } from 'src/components/FormikTextField';
import { useSearchPatients } from 'src/hooks/PatientHook';
import { PatientSearchBarResult } from './PatientSearchBarResult';
import {
  searchContainer,
  searchContainerFocused,
  SEARCH_CONTAINER_TRANSITION,
} from './styles';

export function PatientSearchBar(): JSX.Element {
  const [focused, setFocused] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const searchBarRef = useRef(null);
  const [patients, { refetch, isLoading, isSuccessful, clearResult }] =
    useSearchPatients();
  const patientsLength = patients.length;

  const handleExpandSearchBar = useCallback(() => {
    setFocused(true);
    setTimeout(() => {
      setIsMenuOpened(patientsLength > 0);
    }, SEARCH_CONTAINER_TRANSITION);
  }, [setFocused, patientsLength]);

  const handleContractSearchBar = useCallback(() => {
    setIsMenuOpened(false);
    setTimeout(() => {
      setFocused(false);
    }, SEARCH_CONTAINER_TRANSITION);
  }, [setFocused]);

  const handleChange = useMemo(() => {
    return (event: ChangeEvent<HTMLInputElement>) => {
      if (event.target.value.length > 0) {
        setIsMenuOpened(true);
        refetch(event.target.value);
      }

      if (event.target.value.length === 0) {
        clearResult();
        setIsMenuOpened(false);
      }
    };
  }, [refetch, clearResult]);

  const isEmpty = !isLoading && isSuccessful && patients.length === 0;

  return (
    <Box
      sx={{
        ...searchContainer,
        ...(focused ? searchContainerFocused : {}),
      } as SxProps<Theme>}
      ref={searchBarRef}
    >
      <DefaultTextField
        placeholder="Search for name or ID"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: isLoading && (
            <InputAdornment position="end">
              <CircularProgress size={16} />
            </InputAdornment>
          ),
        }}
        onFocus={handleExpandSearchBar}
        onBlur={handleContractSearchBar}
        onChange={handleChange}
      />
      <PatientSearchBarResult
        anchorEl={searchBarRef?.current}
        patients={patients}
        open={isMenuOpened}
        isEmpty={isEmpty}
      />
    </Box>
  );
}
