import { CssBaseline, ThemeProvider } from '@mui/material';
import { RoutesManager } from './components/RoutesManager';
import { theme } from './theme';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/700.css';
import { createStores, StoreContext } from './stores';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AlertsContainer } from './components/AlertsContainer';

const stores = createStores();

export function App(): JSX.Element {
  return (
    <StoreContext.Provider value={stores}>
      <ThemeProvider theme={theme}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <RoutesManager />
          <CssBaseline />
          <AlertsContainer />
        </LocalizationProvider>
      </ThemeProvider>
    </StoreContext.Provider>
  );
}
