import {
  Box,
  Button,
  ButtonBase,
  ButtonProps,
  CircularProgress,
  SxProps,
  Theme
} from '@mui/material';
import { Link } from 'react-router-dom';
import {
  defaultButtonStyles,
  linkButtonStyles,
  loadingContainerStyles,
} from './styles';

type DefaultButtonProps = ButtonProps & {
  isLoading?: boolean;
  to?: string;
};

export function DefaultButton({
  sx = {},
  isLoading,
  children,
  variant = 'contained',
  to,
  ...rest
}: DefaultButtonProps): JSX.Element {
  return to ? (
    <ButtonBase 
      sx={{ ...linkButtonStyles, ...sx } as SxProps<Theme>} component="div">
      <Link to={to}>{children}</Link>
    </ButtonBase>
  ) : (
    <Button
      variant={variant}
      sx={{ ...defaultButtonStyles, ...sx } as SxProps<Theme>}
      disabled={isLoading}
      {...rest}
    >
      {children}
      {isLoading && (
        <Box sx={loadingContainerStyles}>
          <CircularProgress size="1em" color="inherit" />
        </Box>
      )}
    </Button>
  );
}
