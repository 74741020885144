import { TableRow, TableRowProps, SxProps, Theme } from '@mui/material';
import { rowStyles, rowClickableStyles } from './styles';

export function DefaultTableRow(props: TableRowProps): JSX.Element {
  return (
    <TableRow
      sx={{
        ...rowStyles,
        ...(props.onClick ? rowClickableStyles : {}),
      } as SxProps<Theme>}
      {...props}
    />
  );
}
