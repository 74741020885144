import { debounce, Grid, SxProps, Theme } from '@mui/material';
import { useMemo, useState } from 'react';
import { DefaultTextField } from 'src/components/FormikTextField';
import { yupValidationErrorToObject } from 'src/utils/validation';
import { ValidationError } from 'yup';
import {
  injectionSettingItemStyles,
  injectionSettingLabelStyles,
  timeColumnStyles,
} from './styles';
import { injectionSettingValidation } from './validation';

export type InjectionSettingInputData = {
  timeFrom: string;
  timeTo: string;
  icRatio?: number;
  correctionFactor?: number;
  basalInsulin?: number;
};

type InjectionSettingItemProp = {
  value?: InjectionSettingInputData;
  from: string;
  to: string;
  onChange: (value: InjectionSettingInputData) => void;
};

export function InjectionSettingItem({
  value,
  from,
  to,
  onChange,
}: InjectionSettingItemProp): JSX.Element {
  const { icRatio, correctionFactor, basalInsulin } = value || {};

  const [errors, setErrors] = useState<Record<string, string> | undefined>(
    undefined
  );

  const handleValidation = useMemo(
    () =>
      debounce(async (value: InjectionSettingInputData) => {
        try {
          await injectionSettingValidation.validate(value, {
            abortEarly: false,
          });
          setErrors(undefined);
        } catch (error) {
          setErrors(yupValidationErrorToObject(error as ValidationError));
        }
      }, 500),
    [setErrors]
  );

  const handleInputChange = (event: React.ChangeEvent) => {
    const { name, value } = event.target as HTMLInputElement;

    if (isNaN(Number(value))) {
      return;
    }
    const attribute = name.split('_')[1];
    const newValue = {
      timeFrom: from,
      timeTo: to,
      icRatio,
      correctionFactor,
      basalInsulin,
      [attribute]: value,
    };

    onChange(newValue);
    handleValidation(newValue);
  };

  return (
    <Grid sx={injectionSettingItemStyles} spacing={3} container>
      <Grid 
        item
        sx={{ ...timeColumnStyles, ...injectionSettingLabelStyles } as SxProps<Theme>}>
        {from} - {to}
      </Grid>
      <Grid item xs>
        <DefaultTextField
          onChange={handleInputChange}
          name={`${from}_icRatio`}
          value={icRatio || ''}
          error={!!errors && !!errors['icRatio']}
          helperText={errors && errors['icRatio']}
        />
      </Grid>
      <Grid item xs>
        <DefaultTextField
          onChange={handleInputChange}
          name={`${from}_correctionFactor`}
          value={correctionFactor || ''}
          error={!!errors && !!errors['correctionFactor']}
          helperText={errors && errors['correctionFactor']}
        />
      </Grid>
      <Grid item xs>
        <DefaultTextField
          onChange={handleInputChange}
          name={`${from}_basalInsulin`}
          value={basalInsulin || ''}
          error={!!errors && !!errors['basalInsulin']}
          helperText={errors && errors['basalInsulin']}
        />
      </Grid>
    </Grid>
  );
}
